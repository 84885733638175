import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'

import Loading from '../../components/Loading'
import AppLayout from '../../components/Layouts/AppLayout'
import { useAuth } from '../../hooks/auth'
import Filters from '../../components/customer_portal/Filters'
import ShipToDetails from '../../components/customer_portal/ShipToDetails'
import SalesByItem from '../../components/customer_portal/SalesByItem'
import PendingShipments from '../../components/customer_portal/PendingShipments'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import ShipmentsSummaryData from '../../components/customer_portal/ShipmentsSummaryData'
import { useTranslation } from 'react-i18next'
import PieChartData from '../../components/customer_portal/PieChartData'
import BarChartData from '../../components/customer_portal/BarChartData'
import OnTimeDelivery from '../../components/customer_portal/OnTimeDelivery'
import { isValid } from '../../helpers/helper'
import { isNil } from 'lodash'
import ClearIcon from "@mui/icons-material/Clear"
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack';
import { LinearProgress } from '@mui/material'

const CustomerDashboard = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [loadingProgress, setLoadingProgress] = useState(false)
    const { user } = useAuth({ middleware: 'guest' })
    const { config, pusher, choosesite } = useStateContext()
    const { t } = useTranslation()
    const [customerId, setCustomerId] = useState(user?.customer_id)

    const [isUpdatingOnTimeDelivery, setIsUpdatingOnTimeDelivery] = useState(true)
    const [isUpdatingShipToDetails, setIsUpdatingShipToDetails] = useState(true)
    const [isUpdatingSalesByItem, setIsUpdatingSalesByItem] = useState(true)
    const [isUpdatingUnitsShipped, setIsUpdatingUnitsShipped] = useState(true)
    const [isUpdatingShipmentsSummary, setIsUpdatingShipmentsSummary] = useState(true)
    const [isUpdatingPalletType, setIsUpdatingPalletType] = useState(true)
    const [isUpdatingPendingShipments, setIsUpdatingPendingShipmments] = useState(true)

    /* list of years */
    const [years] = useState([{
        id: dayjs().format('YYYY') - 0,
        year: dayjs().format('YYYY') - 0
    },
    {
        id: dayjs().format('YYYY') - 1,
        year: dayjs().format('YYYY') - 1
    },
    {
        id: dayjs().format('YYYY') - 2,
        year: dayjs().format('YYYY') - 2
    }
    ])

    /* list of months */
    const [months] = useState([
        {
            id: 0,
            name: t('months.full.jan')
        },
        {
            id: 1,
            name: t('months.full.feb')
        },
        {
            id: 2,
            name: t('months.full.mar')
        },
        {
            id: 3,
            name: t('months.full.apr')
        },
        {
            id: 4,
            name: t('months.full.may')
        },
        {
            id: 5,
            name: t('months.full.jun')
        },
        {
            id: 6,
            name: t('months.full.jul')
        },
        {
            id: 7,
            name: t('months.full.aug')
        },
        {
            id: 8,
            name: t('months.full.sep')
        },
        {
            id: 9,
            name: t('months.full.oct')
        },
        {
            id: 10,
            name: t('months.full.nov')
        },
        {
            id: 11,
            name: t('months.full.dec')
        }
    ])


    const [year, setYear] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.customer_portal_sales_year || [dayjs().format('YYYY') - 0]
        } else {
            return [dayjs().format('YYYY') - 0]
        }
    })
    const [shipToLocation, setShipToLocation] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.customer_portal_sales_location || []
        } else {
            return []
        }
    })
    const [month, setMonth] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.customer_portal_sales_month || [months[dayjs().month()]]
        } else {
            return [months[dayjs().month()]]
        }
    })
    const [monthId, setMonthId] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.customer_portal_sales_month_id || [dayjs().month()]
        } else {
            return [dayjs().month()]
        }
    })
    const [shipToLocationId, setShipToLocationId] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.customer_portal_sales_location_id || []
        } else {
            return []
        }
    })

    /* states  */
    const [shipToLocations, setShipToLocations] = useState([]) //list of location
    const [pendingSalesOrders, setPendingSalesOrders] = useState([])
    const [summaryData, setSummaryData] = useState([])
    const [shipmentsByLocation, setShipmentsByLocation] = useState([])
    const [onTimeDelivery, setOnTimeDelivery] = useState({
        ontime: 0,
        total: 0,
        percentage_ontime: 0
    })
    const [quantitiesShipped, setQuantitiesShipped] = useState([])
    const [palletsbytype, setPalletsByType] = useState([])
    const [salesbytype, setSalesByType] = useState([])



    /* effects */

    useEffect(() => {
        if (isValid(choosesite)) {
            const shipmentChannelCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${choosesite}`)
            const shipmentChannelDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
            const shipmentChannelUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${choosesite}`)
            const channelShipAddressDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-shipaddress-deleted`)

            // Bind shipment channel pushes

            shipmentChannelCreate.bind(`${localStorage.getItem('client_id')}-salesorder-created-event-site-${choosesite}`, data => {
                if (Number(data.customer_id) == Number(user?.customer_id)) {
                    /* handleEventSalesOrder(data.id, 'created') */
                    getAllData()
                }
            })

            shipmentChannelDelete.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${choosesite}`, data => {
                if (Number(data.customer_id) == Number(user?.customer_id)) {
                    /* handleEventSalesOrder(data.id, 'deleted') */
                    getAllData()
                }
            })

            shipmentChannelUpdate.bind(`${localStorage.getItem('client_id')}-salesorder-updated-event-site-${choosesite}`, data => {
                if (Number(data.customer_id) == Number(user?.customer_id)) {
                    /* handleEventSalesOrder(data.id, 'updated') */
                    getAllData()
                }
            })

            channelShipAddressDelete.bind(`${localStorage.getItem('client_id')}-shipaddress-deleted-event`, data => {
                if (Number(data.customer_id) == Number(user?.customer_id)) {
                    /* handleEventSalesOrder(data.id, 'updated') */
                    let filters = JSON.parse(localStorage.getItem('filters'))

                    filters[0].customer_portal_sales_location = filters[0].customer_portal_sales_location.filter((location) => location?.id !== data.id)
                    filters[0].customer_portal_sales_location_id = filters[0].customer_portal_sales_location_id.filter((locationId) => locationId !== data.id)

                    localStorage.setItem('filters', JSON.stringify(filters))

                    setShipToLocation(filters[0].customer_portal_sales_location)
                    setShipToLocationId(filters[0].customer_portal_sales_location_id)
                    setShipToLocations((prev) => {
                        return prev.filter((s) => s.id !== data.id)
                    })

                    getAllData()
                }
            })
        }

        return (() => {
            if (isValid(choosesite)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-shipaddress-deleted`)
            }
        })
    }, [choosesite])

    useEffect(() => {
        if (user?.role === 'customers_user') {
            getInsightsUser()
        }
    }, [])


    useEffect(() => {
        if (user?.role === 'customers_user') {
            createFilterArray(year, month, shipToLocation, monthId, shipToLocationId)
            getAllData()
        }
    }, [year, month, monthId, shipToLocation, shipToLocationId])


    const createFilterArray = (year, month, location, month_id, location_id) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].customer_portal_sales_year = year
        filters[0].customer_portal_sales_month = month
        filters[0].customer_portal_sales_location = location
        filters[0].customer_portal_sales_month_id = month_id
        filters[0].customer_portal_sales_location_id = location_id

        localStorage.setItem('filters', JSON.stringify(filters))
    }


    /* functions */
    const getInsightsUser = async () => {
        await axios.get(`/api/insights-users/${user?.id}`, config)
            .then(res => {
                const user = res.data
                setShipToLocations(user?.ship_to_locations_info)

                if (shipToLocation.length < 1 || shipToLocationId.length < 1) {
                    setShipToLocation(user?.ship_to_locations_info)
                    setShipToLocationId(user?.ship_to_locations_info.map(i => i.id))
                }
            })

    }

    const getSalesOrders = async () => {
        setIsUpdatingPendingShipmments(true)

        await axios
            .get(`/api/list-orders?order_type=sales&pending=true&address=${shipToLocationId}&pending_filtered_year=${year}&pending_filtered_month=${monthId}&include_broker`, config)
            .then(res => {
                const data = res.data?.data
                setPendingSalesOrders(data)
            })
            .finally(() => {
                setIsUpdatingPendingShipmments(false)
            })
    }

    const getShipmentSummary = async () => {
        setIsUpdatingShipmentsSummary(true)

        await axios
            .get(`/api/fetch-shipments-summary/${customerId}?filtered_month=${monthId}&filtered_year=${year}&ship_to_location=${shipToLocationId}`, config)
            .then(res => {
                const summary = res.data
                setSummaryData(summary)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('shipments_summary_data')
                    })
                )
            })
            .finally(() => {
                setIsUpdatingShipmentsSummary(false)
            })
    }

    const getOnTimeDelivery = async () => {
        setIsUpdatingOnTimeDelivery(true)

        await axios
            .get(`/api/fetch-shipments-on-time/${customerId}?filtered_month=${monthId}&filtered_year=${year}&ship_to_location=${shipToLocationId}`,/* formData, */ config)
            .then(res => {
                const ontime = res.data
                setOnTimeDelivery(ontime)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('on_time_delivery')
                    })
                )
            })
            .finally(() => {
                setIsUpdatingOnTimeDelivery(false)
            })
    }

    const getShipmentsByLocation = async () => {
        setIsUpdatingShipToDetails(true)

        await axios
            .get(`/api/fetch-shipments-by-location/${customerId}?filtered_month=${monthId}&filtered_year=${year}&ship_to_location=${shipToLocationId}`, config)
            .then(res => {
                const shipmentsbylocation = res.data
                setShipmentsByLocation(shipmentsbylocation)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('ship_to_details')
                    })
                )
            })
            .finally(() => {
                setIsUpdatingShipToDetails(false)
            })
    }

    const getQuantitiesShipped = async () => {
        setIsUpdatingUnitsShipped(true)

        await axios
            .get(`/api/fetch-quantities-shipped/${customerId}?filtered_month=${monthId}&filtered_year=${year}&ship_to_location=${shipToLocationId}`, config)
            .then(res => {
                const quantitiesByItems = res.data
                setQuantitiesShipped(quantitiesByItems)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('units_shipped_month_year')
                    })
                )
            })
            .finally(() => {
                setIsUpdatingUnitsShipped(false)
            })
    }


    const getPalletsByType = async () => {
        setIsUpdatingPalletType(true)

        await axios
            .get(`/api/fetch-shipped-pallets-by-type/${customerId}?filtered_month=${monthId}&filtered_year=${year}&ship_to_location=${shipToLocationId}`, config)
            .then(res => {
                const palletsbytype = res.data
                setPalletsByType(palletsbytype)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('pallet_type_by_pct')
                    })
                )
            })
            .finally(() => {
                setIsUpdatingPalletType(false)
            })
    }

    const getSalesByType = async () => {
        setIsUpdatingSalesByItem(true)

        await axios
            .get(`/api/fetch-sales-by-item-type/${customerId}?filtered_month=${monthId}&filtered_year=${year}&ship_to_location=${shipToLocationId}`, config)
            .then(res => {
                const salesbytype = res.data
                setSalesByType(salesbytype)
            })
            .catch(({response}) => {
                enqueueSnackbar(
                    t('could_not_x_y', {
                        x: t('get'),
                        y: t('sales_by_item')
                    })
                )
            })
            .finally(() => {
                setIsUpdatingSalesByItem(false)
            })
    }

    // Will accept a variable number of functions. An alternative to getAllData wherein not all the data is required.
    const getData = async (...args) => {
        setIsLoading(true)

        const functions = !isNil(choosesite)
            ? [
                ...args
            ] : []

        const functionCount = args.length

        try {
            let counter = 0
            for (const func of functions) {
                await func()
                counter++
                setLoadingProgress(Math.round((counter / functionCount) * 100))
            }
        } catch (error) {
            console.log(error)
        } finally {
            setTimeout(() => {
                setIsLoading(false)
                setLoadingProgress(0)
            }, "500")
        }
    }

    const getAllData = async () => {
        setIsLoading(true)

        const functions = []

        if(!isNil(choosesite)) {
            // List of functions to be executed (for high treason)
            functions.push(
                getSalesOrders,
                getShipmentSummary,
                getShipmentsByLocation,
                getQuantitiesShipped,
                getPalletsByType,
                getSalesByType
            )

            if(!localStorage.getItem('client_id').includes('pallet-book')) {
                functions.push(getOnTimeDelivery)
            }
        }

        const functionCount = functions.length

        try {
            let counter = 0
            for (const func of functions) {
                await func()
                counter++
                setLoadingProgress(Math.round((counter / functionCount) * 100))
            }
        } catch (error) {
            console.log(error)
        } finally {
            setTimeout(() => {
                setIsLoading(false)
                setLoadingProgress(0)
            }, "500")
        }
    }

    return (
        <>
            {/* {isLoading &&
                <Loading
                    progressConfig={{
                        variant: 'determinate',
                        value: loadingProgress
                    }}
                    withLabel
                />
            } */}

            {isLoading && <Loading />}

            <SnackbarProvider
                maxSnack={3}
                /* preventDuplicate */
                autoHideDuration={12000}
                variant='error'
                action={(snackbarId) => (
                    <button onClick={() => closeSnackbar(snackbarId)}>
                        <ClearIcon />
                    </button>
                )}
            />

            <AppLayout>
                <div>

                    <div className='flex max-[1300px]:flex-col'>
                        <div className='max-[1300px]:w-full'>
                            <div>
                                <div className='p-5 w-full flex'>
                                    <div className='bg-white mb-2 rounded-md w-full'>
                                        <Filters
                                            setShipToLocationId={setShipToLocationId}
                                            setMonthId={setMonthId} user={user}
                                            years={years}
                                            months={months}
                                            shipToLocations={shipToLocations}
                                            year={year}
                                            setYear={setYear}
                                            shipToLocation={shipToLocation}
                                            setShipToLocation={setShipToLocation}
                                            month={month}
                                            setMonth={setMonth}
                                        />

                                    </div>
                                    {!localStorage.getItem('client_id').includes('pallet-book') && (
                                        <div className='pl-5 w-[300px] max-[1300px]:w-full'>
                                            <div className='bg-white rounded-md'>
                                                <OnTimeDelivery title={t('on_time_delivery')} total={onTimeDelivery} /* isUpdating={isUpdatingOnTimeDelivery} */ />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='p-5 w-[790px] max-[1300px]:w-full'>
                                    <div className='bg-white rounded-md'>
                                        <div>
                                            <div className='flex p-5 justify-start items-center'>
                                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('units_shipped_month_year')}</p>
                                            </div>
                                        </div>
                                        {/* {isUpdatingUnitsShipped && <LinearProgress />} */}
                                        <BarChartData items={quantitiesShipped} />
                                    </div>
                                </div>
                                <div className='flex'>
                                    <div className='p-5 w-[790px] max-[1300px]:w-full'>
                                        <div className='bg-white rounded-md'>
                                            <div>
                                                <div className='flex p-5 justify-start items-center'>
                                                    <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('pallet_type_by_pct')}</p>
                                                </div>
                                            </div>
                                            {/* {isUpdatingPalletType && <LinearProgress />} */}
                                            <PieChartData items={palletsbytype} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='w-full'>
                            <div className='flex'>
                                <div className='p-5 w-full'>
                                    <div className='bg-white mb-2 rounded-md'>
                                        <ShipToDetails shipToLocation={shipmentsByLocation} /* isUpdating={isUpdatingShipToDetails} */ />
                                    </div>
                                </div>
                                <div className='p-5 w-full'>
                                    <div className='bg-white mb-2 rounded-md'>
                                        <SalesByItem items={salesbytype} /* isUpdating={isUpdatingSalesByItem} */ />
                                    </div>
                                </div>
                            </div>
                            <div className='p-5 w-full'>
                                <div className='bg-white mb-2 rounded-md h-fit'>
                                    <ShipmentsSummaryData items={summaryData} /* isUpdating={isUpdatingShipmentsSummary} */ />
                                </div>
                            </div>
                            <div className='p-5 w-full'>
                                <div className='bg-white mb-2 rounded-md '>
                                    <div className='p-5 border-b'>
                                        <div className='flex justify-start items-center gap-4 '>
                                            <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('pending_shipments')}</p>
                                        </div>
                                    </div>
                                    {/* {isUpdatingPendingShipments && <LinearProgress />} */}
                                    <div className='pt-3'>
                                        <PendingShipments items={pendingSalesOrders} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </AppLayout>
        </>
    )
}

export default CustomerDashboard
