import React, {useEffect, useState} from 'react'

import {
    Stack,
    MenuItem,
    FormControl,
    Select,
    IconButton,
    Box,
    Switch,
    Tab,
    TextField,
    Autocomplete
} from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear"
import {useTranslation} from "react-i18next";
import Swal from 'sweetalert2'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {isNull} from 'lodash';
import { useCountries } from 'use-react-countries'

import axios from '../../lib/axios'
import {useStateContext} from '../../context/ContextProvider'
import ShipToAdresses from '../../components/customers_vendors/ShipToAdresses'
import {useAuth} from '../../hooks/auth'
import SalesContracts from '../../components/customers_vendors/SalesContracts';
import InsightsUsers from '../../components/customer_portal/InsightsUsers';
import Loading from '../../components/Loading';
import {handleAxiosError, isValid} from '../../helpers/helper';
import { TEXTFIELD_STYLE, SELECT_STYLE, AUTOCOMPLETE_STYLE } from '../../constants/constants';

const UpdateCustomer = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const params = useParams()
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { config, getCompanies: getCompaniesContext, companies, pusher, creditTerms } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })
    const { countries } = useCountries()

    const [isLoading, setIsLoading] = useState(false)
    const [value, setValue] = useState(() => {
        if (
            searchParams.has('tab')
            && ['general', 'communication', 'shipping', 'ship-to-locations', 'sales-contracts', 'customer-users'].includes(searchParams.get('tab'))
        ) {
            return searchParams.get('tab')
        } else {
            return 'general'
        }
    })
    const [sites, setSites] = useState([])

    /* FORM FIELDS */
    /* general */
    const [no, setNo] = useState('')
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [address2, setAddress2] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zip, setZip] = useState('')
    const [country, setCountry] = useState(null)
    const [countryInputValue, setCountryInputValue] = useState('')
    const [company, setCompany] = useState('')
    const [blocked, setBlocked] = useState(false)
    const [creditTermId, setCreditTermId] = useState('')
    const [creditTerm, setCreditTerm] = useState(null)
    const [creditTermInputValue, setCreditTermInputValue] = useState('')

    /* communication */
    const [phone, setPhone] = useState('')
    const [fax, setFax] = useState('')
    const [email, setEmail] = useState('')
    const [website, setWebsite] = useState('')
    const [generalCompanyEmail, setGeneralCompanyEmail] = useState('')

    const [salesContactName, setSalesContactName] = useState('')
    const [salesContactFirstName, setSalesContactFirstName] = useState('')
    const [salesContactLastName, setSalesContactLastName] = useState('')

    const [accountingContactName, setAccountingContactName]= useState('')
    const [accountingContactFirstName, setAccountingContactFirstName] = useState('')
    const [accountingContactLastName, setAccountingContactLastName] = useState('')
    const [accountingContactPhoneNo, setAccountingContactPhoneNo] = useState('')
    const [accountingEmail, setAccountingEmail] = useState('')

    const [orderDeskContactName, setOrderDeskContactName] = useState('')
    const [orderDeskContactFirstName, setOrderDeskContactFirstName] = useState('')
    const [orderDeskContactLastName, setOrderDeskContactLastName] = useState('')
    const [orderDeskEmail, setOrderDeskEmail] = useState('')

    /* shipping */
    const [site, setSite] = useState('')
    const [code, setCode] = useState('')

    useEffect(() => {
        if (localStorage.getItem('showImpersonationMessage') !== null) {
            Swal.fire({
                icon: 'success',
                title: t('x_successful', {x: t('impersonation')}),
                text: t('you_are_impersonating_x', {x: localStorage.getItem('impersonatee_name') ?? t('another_user')}),
                showConfirmButton: true,
            }).then((result) => {
                localStorage.removeItem('showImpersonationMessage')
            })
        } else if (localStorage.getItem('showDeimpersonationMessage') !== null) {
            Swal.fire({
                icon: 'success',
                title: t('x_successful', {x: t('deimpersonation')}),
                text: t('you_are_no_longer_impersonating_x', {x: localStorage.getItem('impersonatee_name') ?? t('another_user')}),
                showConfirmButton: true,
            }).then((result) => {
                localStorage.removeItem('showDeimpersonationMessage')
                localStorage.removeItem('impersonatee_name')
            })
        }

        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-customer-deleted`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-customer-updated`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-customer-deleted-event`, data => {
            if(Number(data.id) === Number(params.id)) {
                navigate('/customers')
            }
        })


        channelupdate.bind(`${localStorage.getItem('client_id')}-customer-updated-event`, data => {
            if(Number(data.id) === Number(params.id)) {
                getCustomer()
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-customer-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-customer-updated`)
        })
    }, [])

    /* KEY EVENT */
    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode === '27') {
                navigate('/customers')
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    useEffect(() => {
        getCustomer()
        getCompanies()
    }, [])

    useEffect(() => {
        if (isValid(company)) {
            getSites(company)
        }
    }, [company])

    useEffect(() => {
        setSearchParams({ tab: value })
    }, [value])

    useEffect(() => {
        if(isValid(creditTermId) && creditTerms.length > 0) {
            const target = creditTerms.find((term) => term.id == creditTermId)

            if(target) {
                setCreditTerm(target)
                setCreditTermInputValue(target.name)
            }
        }
    }, [creditTermId, creditTerms])

    const getCompanies = () => {
        if (user?.role === "office_manager") {
            getCompaniesContext()
            setCompany(user?.company_id)
        }
    }

    const getSites = (id) => {
        setIsLoading(true)
        axios
            .get(`/api/sites?company_id=${id}`, config)
            .then(res => {
                const sites = res.data
                setSites(sites)
            })
            .catch(({ response }) => {
                console.error(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getCustomer = async (id = null) => {
        setIsLoading(true)
        await axios
            .get(`/api/customers/${isNull(id) ? params.id : id}`, config)
            .then(res => {
                const data = res.data
                setNo(data.customer_no)
                setName(data.name)
                setAddress(data.address)
                setAddress2(data?.address2 || "")
                setCity(data.city)
                setState(data.state)
                setZip(data.zip)
                setCountry(data.country)
                setCountryInputValue(data.country)
                setCompany(data.company_id)
                setBlocked(data.blocked ? true : false)
                setCreditTermId(data.credit_term_id)
                setPhone(data?.phone || "")
                setFax(data?.fax || "")
                setEmail(data?.email || "")
                setWebsite(data?.website || "")
                setSite(data?.location_id || "")
                setCode(data?.shipping_code || "")
                setGeneralCompanyEmail(data.general_company_email)
                setSalesContactName(data.sales_contact_name)
                setSalesContactFirstName(data?.sales_contact_first_name)
                setSalesContactLastName(data?.sales_contact_last_name)
                setAccountingContactName(data.accounting_contact_name)
                setAccountingContactFirstName(data?.accounting_contact_first_name)
                setAccountingContactLastName(data?.accounting_contact_last_name)
                setAccountingContactPhoneNo(data.accounting_contact_phone_no)
                setAccountingEmail(data.accounting_email)
                setOrderDeskContactName(data.order_desk_contact_name)
                setOrderDeskContactFirstName(data?.order_desk_contact_first_name)
                setOrderDeskContactLastName(data?.order_desk_contact_last_name)
                setOrderDeskEmail(data.order_desk_email)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const updateCustomer = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formData = {}

        /* formData['customer_no'] = no */
        formData['name'] = name
        formData['address2'] = address2
        formData['address'] = address
        formData['company_id'] = company
        formData['location_id'] = site
        formData['fax'] = fax
        formData['phone'] = phone
        formData['website'] = website
        formData['email'] = email
        formData['blocked'] = blocked
        formData['shipping_code'] = code
        formData['city'] = city
        formData['state'] = state
        formData['zip'] = zip
        formData['country'] = country
        formData['credit_term_id'] = creditTermId

        formData['general_company_email'] = generalCompanyEmail
        formData['sales_contact_name'] = salesContactName
        formData['sales_contact_first_name'] = salesContactFirstName
        formData['sales_contact_last_name'] = salesContactLastName
        formData['accounting_contact_name'] = accountingContactName
        formData['accounting_contact_first_name'] = accountingContactFirstName
        formData['accounting_contact_last_name'] = accountingContactLastName
        formData['accounting_contact_phone_no'] = accountingContactPhoneNo
        formData['accounting_email'] = accountingEmail
        formData['order_desk_contact_name'] = orderDeskContactName
        formData['order_desk_contact_first_name'] = orderDeskContactFirstName
        formData['order_desk_contact_last_name'] = orderDeskContactLastName
        formData['order_desk_email'] = orderDeskEmail

        await axios
            .put(`/api/customers/${params.id}`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleClearSiteClick = () => {
        setSite('')
    }
    const handleClearCodeClick = () => {
        setCode('')
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const handleBlocked = (event) => {
        setBlocked(event.target.checked);
    }

    const handleChangeCompany = (id) => {
        setCompany(id)
        getSites(id)
        setSite('')
    }

    const handleChangeCreditTerm = (value) => {
        if(value) {
            setCreditTerm(value)
            setCreditTermId(value.id)
        } else {
            setCreditTerm(null)
            setCreditTermId('')
        }
    }

    return (
        <>

            {isLoading ? <Loading /> : ''}

            <Box>
                <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t('update_customer')} - {name}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <Link to={`/customers`}><i className="fa-solid fa-plus"></i></Link>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-white h-full'>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" variant='scrollable'>
                                <Tab sx={{ textTransform: 'none' }} value="general" label={t('general')} icon={<i className="fa-solid fa-circle-info"></i>} iconPosition='start' />
                                <Tab sx={{ textTransform: 'none' }} value="communication" label={t('communication')} icon={<i className="fa-solid fa-phone"></i>} iconPosition='start' />
                                <Tab sx={{ textTransform: 'none' }} value="shipping" label={t('shipping')} icon={<i className="fa-solid fa-truck"></i>} iconPosition='start' />
                                <Tab sx={{ textTransform: 'none' }} value="ship-to-locations" label={t('ship_to_address')} icon={<i className="fa-solid fa-address-book"></i>} iconPosition='start' />
                                <Tab sx={{ textTransform: 'none' }} value="sales-contracts" label={t('sales_prices')} icon={<i className="fa-solid fa-money-check-dollar"></i>} iconPosition='start' />
                                <Tab sx={{textTransform: 'none'}} value="customer-users" label={t('customer_users')}
                                     icon={<i className="fa-solid fa-users-rays fa-lg"></i>} iconPosition='start'/>
                            </TabList>
                        </Box>
                        <TabPanel value="general">
                            <div>
                                <form className='flex justify-center flex-col items-start mt-2'>
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('no')} *</p>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            className='w-full'
                                            name="customer_no"
                                            value={no}
                                            onChange={(e) => { setNo(e.target.value) }}
                                            disabled
                                        />
                                    </div>
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('name')} *</p>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            className='w-full'
                                            name="customer_name"
                                            value={name}
                                            onChange={(e) => { setName(e.target.value) }}
                                        />
                                    </div>
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('address')} *</p>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            className='w-full'
                                            name="address"
                                            value={address}
                                            onChange={(e) => { setAddress(e.target.value) }}
                                        />
                                    </div>
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('address2')}</p>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            className='w-full'
                                            name="address2"
                                            value={address2}
                                            onChange={(e) => { setAddress2(e.target.value) }}
                                        />
                                    </div>
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('city')} *</p>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            className='w-full'
                                            name="city"
                                            value={city}
                                            onChange={(e) => { setCity(e.target.value) }}
                                        />
                                    </div>
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('state')} *</p>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            className='w-full'
                                            name="state"
                                            value={state}
                                            onChange={(e) => { setState(e.target.value) }}
                                        />
                                    </div>
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('zip')} * </p>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            className='w-full'
                                            name="zip"
                                            value={zip}
                                            onChange={(e) => { setZip(e.target.value) }}
                                        />
                                    </div>
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('country')}</p>
                                        <Autocomplete
                                            disablePortal
                                            id="countries-autocomplete"
                                            options={countries.map(({ name }) => name)}
                                            value={country}
                                            onChange={(a, b) => setCountry(b) }
                                            inputValue={countryInputValue}
                                            onInputChange={(e, value) => setCountryInputValue(value)}
                                            renderInput={(params) => <TextField {...params} /> }
                                            sx={AUTOCOMPLETE_STYLE}
                                        />
                                    </div>

                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('company')} *</p>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Select
                                                value={company}
                                                onChange={(e) => { setCompany(e.target.value) }}
                                                sx={SELECT_STYLE}
                                            >
                                                {companies.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('credit_term')}</p>
                                        <Autocomplete
                                            disablePortal
                                            id="credit-terms-autocomplete"
                                            options={creditTerms}
                                            getOptionLabel={(option) => option.name || ''}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            value={creditTerm}
                                            onChange={(a, b) => handleChangeCreditTerm(b) }
                                            inputValue={creditTermInputValue}
                                            onInputChange={(e, value) => setCreditTermInputValue(value)}
                                            renderInput={(params) => <TextField {...params} /> }
                                            sx={AUTOCOMPLETE_STYLE}
                                        />
                                    </div>

                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('blocked')} </p>
                                        <Switch checked={blocked} onChange={handleBlocked} inputProps={{ 'aria-label': 'controlled' }} />
                                    </div>
                                </form>
                            </div>
                        </TabPanel>
                        <TabPanel value="shipping">
                            <div>
                                <form className='flex justify-center flex-col items-start mt-2'>

                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('default_site')}</p>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Select
                                                value={site}
                                                onChange={(e) => { setSite(e.target.value) }}
                                                sx={{
                                                    ...SELECT_STYLE,
                                                    ".MuiSelect-iconOutlined": {
                                                        display: site ? 'none !important' : ''
                                                    }
                                                }}
                                                endAdornment={site ? (<IconButton sx={{ visibility: site ? "visible" : "hidden", padding: '0' }} onClick={handleClearSiteClick}><ClearIcon /></IconButton>) : false}
                                            >
                                                {sites?.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </div>


                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('shipping_code')}</p>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Select
                                                value={code}
                                                onChange={(e) => { setCode(e.target.value) }}
                                                sx={{
                                                    ...SELECT_STYLE,
                                                    ".MuiSelect-iconOutlined": {
                                                        display: code ? 'none !important' : ''
                                                    }
                                                }}
                                                endAdornment={code ? (<IconButton sx={{ visibility: code ? "visible" : "hidden", padding: '0' }} onClick={handleClearCodeClick}><ClearIcon /></IconButton>) : false}
                                            >
                                                <MenuItem value="CPU">CUSTOMER PICK UP</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </form>
                            </div>
                        </TabPanel>
                        <TabPanel value="communication">
                            <div>
                                <form className='flex justify-center flex-col items-start mt-2'>
                                    {/* General Company Email */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('general_company_email')}</p>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            className='w-full'
                                            name="general_company_email"
                                            value={generalCompanyEmail}
                                            onChange={(e) => { setGeneralCompanyEmail(e.target.value) }}
                                        />
                                    </div>

                                    {/* Sales Contact Name */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('sales_contact_name')}</p>
                                        <Stack direction='row' gap={1}>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                className='grow'
                                                name="sales_contact_first_name"
                                                value={salesContactFirstName}
                                                placeholder={t('first_name')}
                                                onChange={(e) => { setSalesContactFirstName(e.target.value) }}
                                            />

                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                className='grow'
                                                name="sales_contact_last_name"
                                                value={salesContactLastName}
                                                placeholder={t('last_name')}
                                                onChange={(e) => { setSalesContactLastName(e.target.value) }}
                                            />
                                        </Stack>

                                        {/* <div className='w-full'>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                className='w-full'
                                                name="sales_contact_name"
                                                value={salesContactName}
                                                placeholder={t('full_name')}
                                                onChange={(e) => { setSalesContactName(e.target.value) }}
                                            />
                                        </div> */}
                                    </div>

                                    {/* Sales Contact Email */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('sales_contact_email')}</p>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            className='w-full'
                                            name="sales_contact_email"
                                            value={email} onChange={(e) => { setEmail(e.target.value) }}
                                        />
                                    </div>

                                    {/* Sales Contact Phone No. */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('sales_contact_phone_no')}</p>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            className='w-full'
                                            name="sales_contact_phone_no"
                                            value={phone}
                                            onChange={(e) => { setPhone(e.target.value) }}
                                        />
                                    </div>

                                    {/* Accounting Contact Name */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('accounting_contact_name')}</p>
                                        <Stack direction='row' gap={1}>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                className='grow'
                                                name="accounting_contact_first_name"
                                                value={accountingContactFirstName}
                                                placeholder={t('first_name')}
                                                onChange={(e) => { setAccountingContactFirstName(e.target.value) }}
                                            />

                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                className='grow'
                                                name="accounting_contact_last_name"
                                                value={accountingContactLastName}
                                                placeholder={t('last_name')}
                                                onChange={(e) => { setAccountingContactLastName(e.target.value) }}
                                            />
                                        </Stack>

                                        {/* <div className='w-full'>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                className='w-full'
                                                name="accounting_contact_name"
                                                value={accountingContactName}
                                                onChange={(e) => { setAccountingContactName(e.target.value) }}
                                            />
                                        </div> */}
                                    </div>

                                    {/* Accounting Contact Phone No. */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('accounting_contact_phone_no')}</p>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            className='w-full'
                                            name="accounting_contact_name"
                                            value={accountingContactPhoneNo}
                                            onChange={(e) => { setAccountingContactPhoneNo(e.target.value) }}
                                        />
                                    </div>

                                    {/* Accounting Email */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('accounting_email')}</p>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            className='w-full'
                                            name="accounting_email"
                                            value={accountingEmail}
                                            onChange={(e) => { setAccountingEmail(e.target.value) }}
                                        />
                                    </div>


                                    {/* Order Desk Contact Name */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('order_desk_contact_name')}</p>
                                            <Stack direction='row' gap={1}>
                                                <TextField
                                                    sx={TEXTFIELD_STYLE}
                                                    type="text"
                                                    className='grow'
                                                    name="order_desk_contact_first_name"
                                                    value={orderDeskContactFirstName}
                                                    placeholder={t('first_name')}
                                                    onChange={(e) => { setOrderDeskContactFirstName(e.target.value) }}
                                                />

                                                <TextField
                                                    sx={TEXTFIELD_STYLE}
                                                    type="text"
                                                    className='grow'
                                                    name="order_desk_contact_last_name"
                                                    value={orderDeskContactLastName}
                                                    placeholder={t('last_name')}
                                                    onChange={(e) => { setOrderDeskContactLastName(e.target.value) }}
                                                />
                                            </Stack>

                                            {/* <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                className='w-full'
                                                name="order_desk_contact_name"
                                                value={orderDeskContactName}
                                                onChange={(e) => { setOrderDeskContactName(e.target.value) }}
                                            /> */}
                                    </div>

                                    {/* Order Desk Email */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('order_desk_email')}</p>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            className='w-full'
                                            name="order_desk_email"
                                            value={orderDeskEmail}
                                            onChange={(e) => { setOrderDeskEmail(e.target.value) }}
                                        />
                                    </div>

                                    {/* Fax */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('fax')} </p>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            className='w-full'
                                            name="fax"
                                            value={fax}
                                            onChange={(e) => { setFax(e.target.value) }}
                                        />
                                    </div>

                                    {/* Website */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('website')}</p>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            className='w-full'
                                            name="website"
                                            value={website}
                                            onChange={(e) => { setWebsite(e.target.value) }}
                                        />
                                    </div>
                                </form>
                            </div>
                        </TabPanel>
                        <TabPanel value="ship-to-locations" sx={{ background: '#F5F5F9', minHeight: '100vh' }}>
                            <ShipToAdresses no={no} id={params.id} setIsLoading={setIsLoading} />
                        </TabPanel>
                        <TabPanel value="sales-contracts" sx={{ background: '#F5F5F9', minHeight: '100vh' }}>
                            <SalesContracts customerId={params.id} setIsLoading={setIsLoading} customerName={name} />
                        </TabPanel>
                        <TabPanel value="customer-users" sx={{ background: '#F5F5F9', minHeight: '100vh' }}>
                            <InsightsUsers no={no} id={params.id} setIsLoading={setIsLoading} name={name} customerCompanyId={company} customerSiteId={site} />
                        </TabPanel>
                    </TabContext>
                    <div className='w-full flex justify-end px-6 pb-14'>
                        <button type="button" onClick={updateCustomer} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC]'>
                            <span className='pr-2'><i className="fa-solid fa-clock-rotate-left"></i></span> {t('update')}
                        </button>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default UpdateCustomer
