import React, { useEffect, useState } from 'react'

import Swal from 'sweetalert2'
import { Box, InputLabel, TextField, Chip, ListItemIcon, Checkbox, ListItemText, IconButton } from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear"
import { useTranslation } from "react-i18next"
import { useStateContext } from '../../context/ContextProvider'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'
import Loading from '../../components/Loading'
import axios from '../../lib/axios'
import { includes, isNull, split } from 'lodash'

const UpdateUser = () => {

    const { t } = useTranslation();
    const { roles, companies, sites, getSites, config, pusher, permissions } = useStateContext()
    const { user } = useAuth({ middleware: 'auth' })
    const [isLoading, setIsLoading] = useState(false)
    const params = useParams()
    const navigate = useNavigate()

    /* state */
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [company, setCompany] = useState(' ')
    const [role, setRole] = useState('')
    const [site, setSite] = useState(' ')
    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState(null)

    const [filteredPermissionOptions, setFilteredPermissionOptions] = useState([])
    const [chosenPermissions, setChosenPermissions] = useState([])
    const [openPermissions, setOpenPermissions] = useState(false)
    const areAllPermissionsChosen = filteredPermissionOptions.length > 0 && chosenPermissions.length === filteredPermissionOptions.length

    useEffect(() => {

        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-user-deleted`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-user-updated`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-user-deleted-event`, data => {
            if(Number(data.id) == Number(params.id)) {
                navigate('/users')
            }
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-user-updated-event`, data => {
            if(Number(data.id) == Number(params.id)) {
                getUser()
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-user-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-user-updated`)
        })
    }, [])

    useEffect(() => {
        getUser()
    }, [])

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate(-1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    useEffect(() => {
        setFilteredPermissionOptions(permissions.filter((permission) => includes(split(permission.visible_to, ','), role)))
    }, [permissions, role])

    const getUser = async (id = null) => {
        setIsLoading(true)
        await axios.get(`/api/users/${isNull(id) ? params.id : null}`, config)
            .then(res => {
                const user = res.data
                setName(user?.name)
                setEmail(user?.email)
                setCompany(user?.company_id || ' ')
                setSite(user?.site_id || ' ')
                setRole(user?.role_slug)
                setChosenPermissions(user?.permissions)
                setIsLoading(false)
            })
    }

    const editUser = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        const formData = {}
        formData['name'] = name
        formData['role'] = role
        formData['company_id'] = company
        formData['site_id'] = site
        if(includes(['master_admin'], user?.role) && includes(['forklift_driver', 'driver', 'dispatcher', 'production_supervisor'], role)) {
            formData['permissions'] = chosenPermissions.map((permission) => permission.id)
        }

        await axios.put(`/api/users/${params.id}`, formData, config).then((data) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: t('updated_success')
            })
            setIsLoading(false)
        }).catch(({ response }) => {

            if (response.status === 422) {
                setErrors(response.data.errors)
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    customClass: 'error',
                    showCloseButton: true,
                    iconColor: '#FF0000'
                })
            }
            setIsLoading(false)
        })


    }

    const handleChangeCompany = (event) => {
        setCompany(event.target.value)
        setSite(' ')
        getSites(event.target.value)
    }

    const handleChangePermissions = (event) => {
        const { target: { value } } = event

        let duplicateRemoved = []

        value.forEach((permission) => {
            if (duplicateRemoved.findIndex((o) => o.id === permission.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === permission.id)
            } else {
                duplicateRemoved.push(permission)
            }
        })

        if (value[value.length - 1] === "all") {
            setChosenPermissions(chosenPermissions.length === filteredPermissionOptions.length ? [] : filteredPermissionOptions)
            return;
        }

        setChosenPermissions(duplicateRemoved)
    }

    const handleRemovePermission = (e, id) => {
        const shouldDelete = chosenPermissions.find((x) => x.id === id)
        if (shouldDelete) {
            const filtered = chosenPermissions.filter((x) => x.id !== shouldDelete.id)
            if (filtered.length > 0) {
                setChosenPermissions(filtered)
            } else {
                setOpenPermissions(false)
                setChosenPermissions([])
            }
        }
    }

    const handleClearPermissions = () => {
        setChosenPermissions([])
    }

    return (
        <>
            {isLoading ? <Loading /> : null}
            <Box>
                <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t('edit_user')} - {name}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <Link to='/users'><i className="fa-solid fa-plus"></i></Link>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-white h-full'>
                    <div className='bg-white p-6 w-full h-fit'>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('name')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={name} onChange={(e) => { setName(e.target.value) }} />
                            </div>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('email')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    disabled type="text" className='w-full' name="drop_trailer_no" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                            </div>
                        </div>

                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('role')} *</p>
                            <div className='w-full'>
                                <FormControl sx={{ width: '100%' }}>
                                    <Select value={role} onChange={(e) => { setRole(e.target.value) }}
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}>
                                        {roles.map((item, index) => <MenuItem key={index} value={item.slug}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {
                            (user?.role !== "office_manager") ?
                                (role !== "master_admin") ?

                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('company')} *</p>
                                        <div className='w-full'>
                                            <FormControl sx={{ width: '100%' }}>
                                                <Select value={company} onChange={handleChangeCompany}
                                                    sx={{
                                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                    }}>
                                                    {companies.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    : ''
                                : ''
                        }

                        {
                            company ? (role !== "master_admin" && role !== "office_manager" && role !== "salesperson" && role !== "client") ?
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('site')} *</p>
                                    <div className='w-full'>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Select value={site} onChange={e => { setSite(e.target.value) }}
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}>
                                                {sites.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                : '' : ''
                        }

                        {
                            (
                                includes(['master_admin'], user?.role)
                                && includes(['forklift_driver', 'driver', 'dispatcher', 'production_supervisor'], role)
                            ) ? (
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('permissions')}</p>
                                    <div className='w-full'>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Select
                                                multiple
                                                value={filteredPermissionOptions.length <= 0 ? [] : chosenPermissions}
                                                open={openPermissions}
                                                onOpen={() => setOpenPermissions(true)}
                                                onClose={() => setOpenPermissions(false)}
                                                onChange={handleChangePermissions}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((x) => (
                                                            <Chip
                                                                key={`permission-${x.id}`}
                                                                label={t(x.name)}
                                                                size="small"
                                                                onMouseDown={(e) => e.stopPropagation()}
                                                                onDelete={(e) => handleRemovePermission(e, x.id)}
                                                            />
                                                        ))}
                                                    </Box>
                                                )}
                                                sx={{
                                                    width: '100%',
                                                    background: '#FCFCFC',
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '9px !important'
                                                    },
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #EEEFF2 !important'
                                                    },
                                                    ".MuiSelect-iconStandard": {
                                                        display: (chosenPermissions.length > 0) ? 'none !important' : ''
                                                    },
                                                    "&.Mui-focused .MuiIconButton-root": {
                                                        color: 'rgba(0,0,0,.42)'
                                                    }
                                                }}
                                                endAdornment={chosenPermissions ? (<IconButton sx={{ visibility: (chosenPermissions.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearPermissions}><ClearIcon /></IconButton>) : false}
                                            >
                                                {
                                                    filteredPermissionOptions?.length > 0 ?
                                                        <MenuItem value="all">
                                                            <ListItemIcon>
                                                                <Checkbox checked={areAllPermissionsChosen} indeterminate={chosenPermissions.length > 0 && chosenPermissions.length < filteredPermissionOptions.length} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={t('select_all')} />
                                                        </MenuItem>
                                                        :
                                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                }
                                                {
                                                    filteredPermissionOptions.map((permission) =>
                                                        <MenuItem value={permission} key={permission.id}>
                                                            <Checkbox checked={chosenPermissions?.findIndex((i) => i.id === permission.id) >= 0} />
                                                            <ListItemText primary={t(permission.name)} />
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className='w-full flex justify-end px-6 pb-14'>
                        <button type="button" onClick={editUser} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC]'>
                            <span className='pr-2'><i className="fa-solid fa-clock-rotate-left"></i></span> {t('update')}
                        </button>
                    </div>
                </div>

            </Box>
        </>
    )
}

export default UpdateUser
